import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as constants from 'newui/constants.js'
import style from './style.module.scss'
import { displayPage } from 'newui/Utils/routerUtil';
import * as backendService from 'newui/Services/Backend/backendService'
import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService'
import ApiPersistence from 'newui/Utils/ApiPersistence';
import { useIntl } from 'react-intl';

import ScreenLogin, { LOGIN_STEP_EMAIL, LOGIN_STEP_PASSWORD, LOGIN_STEP_REMEMBERME } from './step/ScreenLogin';
import ScreenForgetPassword, { FORGET_PASSWORD_STEP_EMAIL } from './step/ScreenForgetPassword';
import { displayErrorNotification, displayInfoNotification } from 'newui/Utils/uiUtil';
import ApiCache from 'newui/Utils/ApiCache';
import Header from '../../Modal/Header';
import Footer from '../../Modal/Footer';
import { dateToYYYYMMDDHHmmss } from 'newui/Utils/dateUtil';

function Login(props) {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const SCREEN_LOGIN = 1;
  const SCREEN_FORGETPASSWORD = 2;

  const MSG_ACTION_RE_SEND_VERIFICATION_EMAIL = "MSG_ACTION_RE_SEND_VERIFICATION_EMAIL";

  const [msgLogin, setMsgLogin] = useState({ text: null, action: null })
  const [msgForgetPassword, setMsgForgetPassword] = useState({ text: null, action: null })
  const [cssEffect, setCssEffect] = useState(constants.STRING_EMPTY)
  const [stepData, setStepData] = useState(() => setBasicData())
  const [currentStep, setCurrentStep] = useState((props.forgetPasswordAsked && props.forgetPasswordAsked === true) ? SCREEN_FORGETPASSWORD : SCREEN_LOGIN);

  function setBasicData() {
    var toReturn = [
      { valid: false, fields: new Map() },
      { valid: false, fields: new Map() }
    ]

    var cached = ApiPersistence.getInstance().getRememberMe();

    if (cached) {
      var theField = new Map()
      theField.set(LOGIN_STEP_EMAIL, { valid: true, value: cached[0] });
      theField.set(LOGIN_STEP_PASSWORD, { valid: true, value: cached[1] });
      theField.set(LOGIN_STEP_REMEMBERME, { valid: true, value: true });

      toReturn = [
        { valid: true, fields: theField },
        { valid: false, fields: new Map() }
      ]

    }

    return toReturn;
  }

  function createMessage(text, action) {
    return { text: text, action: action }
  }

  function setStepValidity(arrayIndex, value, data) {
    var newArray = [...stepData];
    newArray[arrayIndex].valid = value;
    newArray[arrayIndex].fields = data;
    setStepData(newArray);
  }

  function validationStep1CallBack(isValid, data) {
    setStepValidity(0, isValid, data);
  }

  function validationStep2CallBack(isValid, data) {
    setStepValidity(1, isValid, data);
  }


  function successLoginCallbackJWT(data) {

    props.spinnerOff()

    if (data && data.result === 'KO') {
      //Login is not good
      if (data.reason === 'BANNED_BREAK') {
        let msg = intl.formatMessage({ id: 'newcasino.auth.log.error.break', defaultMessage: 'You took a break from the game! You can login again on ' }) + dateToYYYYMMDDHHmmss(new Date(data.date))
        displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), msg);
        props.closeModal();
      }
      else if (data.reason === 'BANNED_SELF') {
        let msg = intl.formatMessage({ id: 'newcasino.auth.log.error.break', defaultMessage: 'You took a break from the game! You can login again on ' }) + dateToYYYYMMDDHHmmss(new Date(data.date))
        displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), msg);
        props.closeModal();
      }
      else if (data.reason === 'NOTVERIFIED') {
        setMsgLogin(createMessage(intl.formatMessage({ id: 'newcasino.auth.log.error.emailnotverified', defaultMessage: 'Your email is not verified. Please go to your mailbox and click on the verification link. If you want to received another verification email, click this message.' }), MSG_ACTION_RE_SEND_VERIFICATION_EMAIL))
      } else {
        setMsgLogin(createMessage(intl.formatMessage({ id: 'newcasino.auth.log.error.badcredential', defaultMessage: 'Incorrect email or password' })))
        shake();
      }
    } else {

      if (data.freespinavailable > 0) {
        displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.auth.login.avail.freespin', defaultMessage: 'You have freespins available, you can use them on one of the games under the "Freespins" Tab just below.' }));
      }

      if (data.alertIntervalMin > -1) {
        ApiPersistence.getInstance().setReminderAlarmMinutes(data.alertIntervalMin);
      } else {
        ApiPersistence.getInstance().setReminderAlarmMinutes(-1);
      }
      //Login is good
      if (stepData[0].fields.get(LOGIN_STEP_REMEMBERME).value === true) {
        ApiPersistence.getInstance().setRememberMe(stepData[0].fields.get(LOGIN_STEP_EMAIL).value, '')
      } else {
        ApiPersistence.getInstance().deleteRememberMe()
      }
      props.closeModal();

      var cacheInstance = ApiCache.getInstance()
      if (cacheInstance) {
        cacheInstance.removeFromCache(backendService.CACHE_POPUP_REQUEST)
      }
      displayPage(constants.ROUTE_HOME, navigate, location.pathname)
    }
  }

  function failureLoginCallbackJWT(data) {
    props.spinnerOff()
    setMsgLogin(createMessage(intl.formatMessage({ id: 'newcasino.auth.log.error.unknown', defaultMessage: 'Oups... Something went wrong, please contact support' })))
  }

  function shake() {
    setCssEffect(style.master_shake);
  }

  function resendVerificationEmailSuccessCallBack(data) {
    props.spinnerOff();
    props.closeModal();
    displayInfoNotification(intl.formatMessage({ id: 'newcasino.auth.log.msg.verifemail.title', defaultMessage: 'Check your inbox!' }), intl.formatMessage({ id: 'newcasino.auth.log.msg.verifemail.msg', defaultMessage: 'We just sent you an email containing a link to verify your email address.' }))
  }

  function resendVerificationEmailFailureCallBack(data) {
    props.spinnerOff();
    displayErrorNotification('Oups', intl.formatMessage({ id: 'newcasino.auth.log.error.unknown', defaultMessage: 'Oups... Something went wrong, please contact support' }))
  }

  function msgCallback(msg) {
    if (msg === MSG_ACTION_RE_SEND_VERIFICATION_EMAIL) {
      props.spinnerOn();
      backendService.resendVerificationEmail(stepData[0].fields.get(LOGIN_STEP_EMAIL).value, resendVerificationEmailSuccessCallBack, resendVerificationEmailFailureCallBack, navigate, location.pathname);
    }

  }

  function login() {
    if (stepData[0].valid === true) {
      props.spinnerOn();
      setCssEffect('');
      setMsgLogin(createMessage(null));
      jwtBackendService.login(stepData[0].fields.get(LOGIN_STEP_EMAIL).value, stepData[0].fields.get(LOGIN_STEP_PASSWORD).value, stepData[0].fields.get(LOGIN_STEP_REMEMBERME).value, successLoginCallbackJWT, failureLoginCallbackJWT, navigate, location.pathname);
    }
  }

  function sendEmail() {
    props.spinnerOn();
    setCssEffect('');
    setMsgForgetPassword(createMessage(null));
    backendService.passwordReset(stepData[1].fields.get(FORGET_PASSWORD_STEP_EMAIL).value, successPasswordResetCallback, failurePasswordResetCallback, navigate, location.pathname)
  }

  function successPasswordResetCallback(data) {
    props.spinnerOff();
    props.closeModal();
    displayInfoNotification(intl.formatMessage({ id: 'newcasino.auth.log.msg.passreset.title', defaultMessage: 'Check your inbox!' }), intl.formatMessage({ id: 'newcasino.auth.log.msg.passreset.msg', defaultMessage: 'We just sent you an email containing a link to reset your password.' }))
  }

  function failurePasswordResetCallback(data) {
    props.spinnerOff();
    displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.contact.support', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
  }

  function onForgotPassword() {
    setCurrentStep(SCREEN_FORGETPASSWORD);
  }

  function displayRegisterPage() {
    props.closeModal();
    displayPage(constants.ROUTE_REGISTER, navigate);
  }

  try {
    var header = null;
    var body = null;
    var footer = null;
    if (currentStep === SCREEN_LOGIN) {

      header = <Header title={intl.formatMessage({ id: 'newcasino.auth.login.title', defaultMessage: 'Login' })} subTitle={intl.formatMessage({ id: 'newcasino.auth.login.subTitle', defaultMessage: 'Welcome to Numbers Game! Please provide your login information' })} closeModal={props.closeModal} />
      body = <ScreenLogin validatorInformer={validationStep1CallBack} value={stepData[0]} onEnterPress={login} msgCallback={msgCallback} message={msgLogin} onForgotPassword={onForgotPassword} />
      footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.auth.login.button.cancel', defaultMessage: 'Cancel' }), action: props.closeModal, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.auth.login.button.login', defaultMessage: 'Login' }), action: login, canProcess: stepData[0].valid }} subTitle={{ text: intl.formatMessage({ id: 'newcasino.auth.login.button.noaccount', defaultMessage: 'Don\'t have an account?' }), link: intl.formatMessage({ id: 'newcasino.auth.login.button.signup', defaultMessage: 'Sign up now!' }), linkAction: displayRegisterPage }} />

    }

    if (currentStep === SCREEN_FORGETPASSWORD) {
      header = <Header title={intl.formatMessage({ id: 'newcasino.auth.login.reset.title', defaultMessage: 'Reset Password' })} subTitle={intl.formatMessage({ id: 'newcasino.auth.login.reset.subTitle', defaultMessage: 'You forgot your password? No problem, we will send you a email to reset it.' })} closeModal={props.closeModal} />
      body = <ScreenForgetPassword validatorInformer={validationStep2CallBack} value={stepData[1]} msgCallback={msgCallback} message={msgForgetPassword} />
      footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.auth.login.button.cancel', defaultMessage: 'Cancel' }), action: props.closeModal, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.auth.login.reset.button.send', defaultMessage: 'Send Email' }), action: sendEmail, canProcess: stepData[1].valid }} subTitle={{ text: intl.formatMessage({ id: 'newcasino.auth.login.button.noaccount', defaultMessage: 'Don\'t have an account?' }), link: intl.formatMessage({ id: 'newcasino.auth.login.button.signup', defaultMessage: 'Sign up now!' }), linkAction: displayRegisterPage }} />
    }

    return (
      <>
        <div>
          <div>
            {header}
          </div>
          <div className={`${cssEffect}`}>
            {body}
          </div>
          <div>
            {footer}
          </div>
        </div>
      </>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default Login